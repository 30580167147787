'use client';

export default function GlobalError({ error, reset }: { error: Error & { digest?: string }; reset: () => void }) {
  const currentTimestamp = new Date().toLocaleString();

  return (
    <html>
      <body className="bg-gray-100 flex min-h-screen items-center justify-center">
        <div className="w-full max-w-md rounded-lg bg-white p-6 text-center shadow-lg">
          <h2 className="text-red-600 mb-4 text-2xl font-bold">Oops! Something went wrong.</h2>
          <p className="mb-4">We encountered an unexpected issue. Please try the following steps:</p>
          <ul className="mb-4 list-inside list-disc text-left">
            <li>
              <strong>Refresh the page</strong> or restart the application.
            </li>
            <li>
              <strong>Check your internet connection</strong> to ensure it is stable.
            </li>
            <li>
              <strong>Clear your browser cache</strong> or application data.
            </li>
          </ul>
          <p className="mb-4">
            If the problem persists, please contact our support team with the following information:
          </p>
          <div className="bg-gray-100 mb-4 rounded p-4 text-left">
            <p>
              <strong>Error Code:</strong> {error?.digest || 'UNK-001'}
            </p>
            <p>
              <strong>Timestamp:</strong> {currentTimestamp}
            </p>
          </div>
          <p className="mb-4">
            We apologize for the inconvenience and appreciate your patience as we work to resolve this issue.
          </p>
          <button
            onClick={() => reset()}
            className="bg-blue-500 hover:bg-blue-600 rounded px-4 py-2 text-white transition duration-200"
          >
            Try again
          </button>
        </div>
      </body>
    </html>
  );
}
